import React, { useEffect, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "../App.css";
import Aos from 'aos';
import 'aos/dist/aos.css';

function Contact() {
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // toast.loading("Loading....");
    // db.collection("Contact")
    //   .add({
    //     email: Email,
    //     fullName: FullName,
    //     message: Message,
    //     mobileNumber: MobileNumber,
    //     Subject: Subject,
    //   })
    //   .then((response) => {
    //     toast.dismiss();
    //     toast.success("Your message has been sent. Thank you!", {
    //       position: "top-center",
    //       autoClose: false,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: 0,
    //     });
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     toast.error(err);

    //     console.log(err);
    //   });
  };

  useEffect(() => {
    Aos.init({ duration: 1200 })
  })

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <main id="main">

        <section id="contact" class="contact">
          <div class="container">

            <div class="section-header" data-aos="fade-up">
              <h2>Contact</h2>
            </div>

            <div data-aos="flip-left" data-aos-duration="2000">
              <iframe style={{ border: "0", width: "100%", height: "270px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1833.1451097080383!2d69.64258785790045!3d23.232523860926545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39511fbe5ea1dedd%3A0x2bd9127b69f925db!2sOdhav%20Technologies%20LLP!5e0!3m2!1sen!2sin!4v1663660454626!5m2!1sen!2sin" frameborder="0" allowfullscreen=""></iframe>
            </div>

            <div class="row mt-4">

              <div class="col-lg-6">

                <div class="row">
                  <div class="col-md-6" data-aos="zoom-in-up">
                    <div class="info-box mt-4">
                      <i class="bi bi-geo-alt"></i>
                      <h3>Address</h3>
                      <p>
                        217, Time Square Empire, Mirzapar Bypass Rd, Bhuj,
                        370001
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6" data-aos="zoom-in-up">
                    <div class="info-box mt-4">
                      <i class="bi bi-envelope"></i>
                      <h3>Email Us</h3>
                      <a href="mailto:contact@odhavtechnologies.com"><p>contact@odhavtechnologies.com</p></a>
                      <a href="mailto:odhavtechnologies@gmail.com"><p>odhavtechnologies@gmail.com</p></a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="info-box mt-4" data-aos="zoom-in-up">
                      <i class="bi bi-telephone"></i>
                      <h3>Call Us</h3>
                      <p>
                        <a href="tel:+919586592853">+91 9586592853</a>
                        <br />
                        <a href="tel:+919016490168">+91 9016490168</a>
                        <br />
                        <a href="tel:+919265753149">+91 9265753149</a>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="info-box mt-4" data-aos="zoom-in-up">
                      <i class="bi bi-clock"></i>
                      <h3>Open Hours</h3>
                      <p>
                        Monday - Friday
                        <br />
                        9:00AM - 06:00PM
                      </p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-lg-6 mt-4 mt-md-0">
                <form onSubmit={handleSubmit} class="php-email-form">
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Your Name"
                        required
                        value={FullName}
                        onChange={(value) => setFullName(value.target.value)}
                      />
                    </div>

                    <div class="col-md-6">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="Your Email"
                        required
                        value={Email}
                        onChange={(value) => setEmail(value.target.value)}
                      />
                    </div>
                    <div class="col-md-12">
                      <input
                        type="number"
                        class="form-control"
                        name="mobilenumber"
                        placeholder="Mobile Number"
                        required
                        value={MobileNumber}
                        onChange={(value) =>
                          setMobileNumber(value.target.value)
                        }
                      />
                    </div>
                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control"
                        name="Subject"
                        placeholder="Subject"
                        required
                        value={Subject}
                        onChange={(value) => setSubject(value.target.value)}
                      />
                    </div>

                    <div class="col-md-12">
                      <textarea
                        class="form-control"
                        name="Message"
                        rows="5"
                        placeholder="Message"
                        required
                        value={Message}
                        onChange={(value) => setMessage(value.target.value)}
                      ></textarea>
                    </div>

                    <div class="col-md-12 text-center">
                      <Button type="submit" color="info">
                        Send Message
                      </Button>
                    </div>
                  </div>
                </form>
              </div>

            </div>

          </div>
        </section>
      </main>
    </div>
  );
}

export default Contact;
