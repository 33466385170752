import "./App.css";
import Navbars from "./components/navbar";
import Topbar from "./components/topbar";
import Footer from "./components/footer";
import Blogs from "./components/blogs";

export default function BlogApp() {
    return (
        <div>
            <div className="App">
                <Navbars />
                <Topbar />
                <Blogs />
                <Footer />
            </div>
        </div>
    );
}
