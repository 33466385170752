import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import react from "../img/react.png";
import android from "../img/android.png";
import laravel from "../img/laravel.png";
import flutter from "../img/flutter.png";
import wordpress from "../img/wordpress.png";
import go from "../img/go.png";
import ios from "../img/ios.png";


export default function techSlider() {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    dots: true,
    items: 4,
    loop: true,
    animateOut: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <section id="technology" class="clients">
      <div class="container col-md-10">
        <header class="section-header" data-aos="zoom-in-down">
          <h2>Our Expertise</h2>
        </header>

        <div data-aos="zoom-out-up">
          <OwlCarousel className="slider-items owl-carousel" {...options}>
            <div class="item">
              <img src={android} />
            </div>
            <div class="item">
              <img src={ios} />
            </div>
            <div class="item">
              <img src={flutter} />
            </div>{" "}
            <div class="item">
              <img src={laravel} />
            </div>{" "}
            <div class="item">
              <img src={react} />
            </div>{" "}
            <div class="item">
              <img src={wordpress} />
            </div>
            <div class="item">
              <img src={go} />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}