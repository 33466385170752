import React from 'react';

const Topbar = () => {
  return (
    <section id="topbar" class="topbar fixed-top d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@odhavtechnologies.com">contact@odhavtechnologies.com</a></i>
          <i class="bi bi-phone d-flex align-items-center ms-4"><a href="tel:+919586592853">9586592853</a></i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a href="https://join.skype.com/invite/XgK2S5ptfxxd" target="_blank"><i class="bi bi-skype"></i></a>
          <a href="https://www.facebook.com/OdhavTechnologies/" target="_blank"><i class="bi bi-facebook"></i></a>
          <a href="https://www.instagram.com/odhavtechnologies/" target="_blank"><i class="bi bi-instagram"></i></a>
          <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGoEUmj62pA9QAAAXxFE36wPUO73EsfWXKyR4lEY5Ug-M1EgmUZfTpj4ufFpS3OSAiQ8FRba4i0Gh17n-TrmjiISr06gYsTlZckG3_kB1xFNQK3e24mAtCt7Ce35vb3c8c-SHU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fodhav-technologies-539885219" target="_blank"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </section>
  )
}
export default Topbar;