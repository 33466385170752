import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../App.css";
import logo from "../img/logo1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";

function Navbars() {
  const [show, setShow] = useState(false);

  const history = useHistory();

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e) => {
    setShow1(!show1);
  };
  const hideDropdown1 = (e) => {
    setShow1(false);
  };
  const [show2, setShow2] = useState(false);
  const showDropdown2 = (e) => {
    setShow2(!show2);
  };
  const hideDropdown2 = (e) => {
    setShow2(false);
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        bg="light"
        variant="info"
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="60"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ padding: 2 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="#335AA8"
              class="bi bi-filter-right"
              viewBox="0 0 16 16"
            >
              <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
            </svg>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/#hero">Home</Nav.Link>
              <NavDropdown
                title="Company"
                id="collasible-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item href="/#about">ABOUT US</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push({ pathname: `/teams` })}>OUR TEAM</NavDropdown.Item>
                 <NavDropdown.Item onClick={() => history.push({ pathname: `/projectportfolio` })}>PORTFOLIO</NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => history.push({ pathname: `/allclient` })}>OUR CLIENTS</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/allclient">
                  OUR CLIENTS
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/#clients">OUR CLIENTS</NavDropdown.Item>

              </NavDropdown>
              <NavDropdown
                title="Service"
                id="navbarScrollingDropdown"
                show={show1}
                onMouseEnter={showDropdown1}
                onMouseLeave={hideDropdown1}
              >
                <NavDropdown.Item href="/#services">
                  WEB DESIGN
                </NavDropdown.Item>
                <NavDropdown.Item href="/#services">
                  WEB DEVELOPMENT
                </NavDropdown.Item>
                <NavDropdown.Item href="/#services" style={{ marginRight: 23 }}>
                  MOBILE APP DEVELOPMENT{"       "}
                </NavDropdown.Item>
                <NavDropdown.Item href="/#services">
                  ERP SOLUTIONS
                </NavDropdown.Item>
                <NavDropdown.Item href="/#services">
                  E-COMMERCE SOLUTIONS
                </NavDropdown.Item>{" "}
                <NavDropdown.Item href="/#services">
                  TECHNICAL SUPPORT
                </NavDropdown.Item>{" "}
                <NavDropdown.Item href="/#services">
                  DOMAIN & HOSTING
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Technology"
                id="navbarScrollingDropdown"
                show={show2}
                onMouseEnter={showDropdown2}
                onMouseLeave={hideDropdown2}
              >
                <NavDropdown.Item href="/#technology">ANDROID</NavDropdown.Item>
                <NavDropdown.Item href="/#technology">FLUTTER</NavDropdown.Item>
                <NavDropdown.Item href="/#technology">LARAVEL</NavDropdown.Item>
                <NavDropdown.Item href="/#technology">GO</NavDropdown.Item>

                <NavDropdown.Item href="/#technology">
                  REACT JS
                </NavDropdown.Item>{" "}
                <NavDropdown.Item href="/#technology">
                  BOOTSTRAP
                </NavDropdown.Item>{" "}
                <NavDropdown.Item href="/#technology">
                  WORDPRESS
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => history.push({ pathname: `/blogs` })}>Blogs</Nav.Link>
              <Nav.Link href="/#contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbars;
