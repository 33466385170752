import "aos/dist/aos.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Topbar from "./topbar";
import { useEffect } from "react";
import { useState } from "react";
import { TeamsApi } from "../config/API";
import { useHistory } from "react-router-dom";
import Aos from "aos";

export default function AllTeam() {
  const history = useHistory();
  const [data, setdata] = useState("");

  useEffect(() => {
    getdata();
    Aos.init({ duration: 1000 })
  }, []);

  const getdata = () => {
    TeamsApi.Teams()
      .then((response) => {
        setdata(response.data)
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const [Teamss, setTeamss] = useState([
  //   {
  //     name: "Saurabh Ramani",
  //     position: "Chief Executive Officer",
  //     social: true,
  //     fade_delay: "200",
  //     img: "assets/img/team/team-1.png",
  //     socialArr: [
  //       {
  //         href: "https://twitter.com/PatelSaurbah?s=09",
  //         class: "bi bi-twitter",
  //       },
  //       {
  //         href: "https://www.facebook.com/profile.php?id=100064926111571",
  //         class: "bi bi-facebook",
  //       },
  //       {
  //         href: "https://www.instagram.com/saurabhpatel8767?utm_medium=copy_link",
  //         class: "bi bi-instagram",
  //       },
  //       {
  //         href: "https://in.linkedin.com/in/saurabh-ramani-331208217",
  //         class: "bi bi-linkedin",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Uday Ramani",
  //     position: "Chief Technical Officer",
  //     social: true,
  //     fade_delay: "300",

  //     img: "assets/img/team/team-2.png",

  //     socialArr: [
  //       {
  //         href: "https://twitter.com/Uday__ramani",
  //         class: "bi bi-twitter",
  //       },
  //       {
  //         href: "https://www.facebook.com/UdayRamani25",
  //         class: "bi bi-facebook",
  //       },
  //       {
  //         href: "https://www.instagram.com/uday__ramani/",
  //         class: "bi bi-instagram",
  //       },
  //       {
  //         href: "https://in.linkedin.com/in/uday-ramani-ab161615b?trk=people-guest_people_search-card",
  //         class: "bi bi-linkedin",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Kaushal Dholu",
  //     fade_delay: "400",

  //     img: "assets/img/team/team-3.png",

  //     position: "Mobile Application Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Pritesh Kerai",
  //     img: "assets/img/team/team-4.png",
  //     fade_delay: "500",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Bhakti Varsani",
  //     img: "assets/img/team/team-5.png",
  //     fade_delay: "200",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Hiral Patel",
  //     img: "assets/img/team/team-6.png",
  //     fade_delay: "300",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Khushbu Gohil",
  //     img: "assets/img/team/team-7.png",
  //     fade_delay: "400",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Fenil Dholu",
  //     img: "assets/img/team/team-9.png",
  //     fade_delay: "500",

  //     position: "Mobile Application Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Tammana Patel",
  //     img: "assets/img/team/team-8.png",
  //     fade_delay: "200",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  //   {
  //     name: "Mukti Bhanushali",
  //     img: "assets/img/team/team-8.png",
  //     fade_delay: "300",

  //     position: "Web Developer",
  //     social: false,
  //     socialArr: [],
  //   },
  // ]);

  if (!data) return null;

  return (
    <div>
      <Navbar />
      <Topbar />
      <div class="breadcrumbs" id="teamH">
        <div
          class="page-header d-flex align-items-center"
          style={{ backgroundImage: "url(assets/img/team/group-1.jpeg)" }}
        >
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 text-center">
                <h2>Our Teams

                </h2>
                <p>
                  Teamwork is the ability to work together toward a common
                  vision, the ability to direct individual accomplishments
                  toward organizational objectives. It is the fuel that allows
                  common people to attain uncommon results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">
          <div class="row">
            {data.data.map((value) => {
              return (
                <div
                  class="col-lg-3 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div class="member">
                    <div class="member-img">
                      <img src={value.image} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>{value.name}</h4>
                      <span>{value.position}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
