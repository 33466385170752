import { useEffect } from "react";
import "../App.css";
import Aos from 'aos';
import 'aos/dist/aos.css';

function Service() {

  useEffect(() => {
    Aos.init({ duration: 1800 })
  })

  return (
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Services</h2>
        </div>

        <div class="row gy-5">
          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="200">
            <div class="icon-box">
              <img src="assets/img/webapp.png" width="100" alt="" />
              <br />
              <br />
              <h4 class="title">
                WEB DESIGN & <>DEVELOPMENT</>
              </h4>
              <p class="description">
                We provide the service for Professional creative web design.
                We create static Websites, WordPress, CMS and Custom
                Programming etc.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="300">
            <div class="icon-box">
              <img src="assets/img/mobileapp.png" width="100" alt="" />
              <br />
              <br />
              <h4 class="title">
                MOBILE APP DEVELOPMENT
              </h4>
              <p class="description">
                We provide the service for Android or iOS Mobile application
                development with high quality. We have made apps that are
                featured in trending sections on Google play.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="400">
            <div class="icon-box">
              <img
                src="assets/img/erp.png"
                width="130"
                height="100"
                alt=""
              />
              <br />
              <br />
              <h4 class="title">ERP SOLUTIONS</h4>
              <p class="description">
                We offer platform-specific and cross-platform desktop app
                development services to help you turn a solid software idea
                into reality.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="200">
            <div class="icon-box">
              <img src="assets/img/ecom.png" width="180" alt="" />
              <br />
              <br />
              <h4 class="title">
                E-COMMERCE <>SOLUTIONS</>
              </h4>
              <p class="description">
                The eCommerce service provided by us are customized for new
                eCommerce players or expert which is packed with standard
                functionalities and payment gateway integration.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="300">
            <div class="icon-box">
              <img src="assets/img/help.png" width="100" alt="" />
              <br />
              <br />
              <h4 class="title">
                TECHNICAL
                SUPPORT
              </h4>
              <p class="description">
                The team of experts at OT is available to help your business
                run smoother and overcome day-to-day issues business team is
                facing making them harder to work and complete their tasks
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-delay="400">
            <div class="icon-box">
              <img src="assets/img/cloud.png" width="100" alt="" />
              <br />
              <br />
              <h4 class="title">
                DOMAIN & HOSTING
              </h4>
              <p class="description">
                A domain is web property to our clients and we provide
                managed domain registration service along with standard web
                hostings for it. We also offer cloud hosting and setup and
                manage as per customer's need.
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Service;
