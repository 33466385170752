import React, { useEffect, useState } from "react";
import c1 from "../img/clients/client_1.png";
import c2 from "../img/clients/client_2.png";
import c3 from "../img/clients/client_3.png";
import c4 from "../img/clients/client_4.png";
import c5 from "../img/clients/client_5.png";
import c6 from "../img/clients/client_6.png";
import c7 from "../img/clients/client_7.png";
import Aos from 'aos';
import "../App.css";
import 'aos/dist/aos.css';

import OwlCarousel from "react-owl-carousel";
function Client() {
  const [Team, setTeam] = useState([]);
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    dots: true,
    items: 4,
    loop: true,
    smartSpeed: 2000,
    checkVisible: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  useEffect(() => {
    Aos.init({ duration: 1500 })
  })
 
  return (
    <>
      <section id="clients" class="clients">
        <div class="container">
          <div class="section-header">
            <h2>Our Clients</h2>
          </div>

          <div data-aos="zoom-in-up">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              <div class="item">
                <img src={c1} />
              </div>
              <div class="item">
                <img src={c2} />
              </div>{" "}
              <div class="item">
                <img src={c3} />
              </div>{" "}
              <div class="item">
                <img src={c4} />
              </div>{" "}
              <div class="item">
                <img src={c5} />
              </div>
              <div class="item">
                <img src={c6} />
              </div>
              <div class="item">
                <img src={c7} />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default Client;
