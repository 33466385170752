import "aos/dist/aos.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Topbar from "./topbar";

export default function ProjectPortfolio() {


  return (
    <div>
      <Navbar />
      <Topbar />
      <div class="breadcrumbs" id="teamH">
        <div
          class="page-header d-flex align-items-center"
          style={{ backgroundImage: "url(assets/img/team/portfolio.jpg)" }}
        >
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 text-center">
                <h2>Our Portfolio

                </h2>
                  <p>
                <div className="col-lg-9 count-box">
                  <span data-purecounter-start="0" data-purecounter-end="65" className="purecounter" data-purecounter-duration="0">40 +</span>
                  <p>Projects</p>
                </div>    
                <div class="vl"></div>
                 <div className="col col-lg-12 company-count">
                  <span data-purecounter-start="0" data-purecounter-end="65" className="purecounter" data-purecounter-duration="0">10 +</span>
                  <p>Company</p>
                </div>              
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="portfolio" class="portfolio section-bg">
          <div class="container" data-aos="fade-up">
            <div class="row">


              
            <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/navyatech.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Navya Tech</h5>
                    <h4>Navya Tech</h4>
                    <span>Application offers you the opportunity to visualize and design your network infrastructure with ease. </span>
                    <h6>August 2021</h6>
                  </div>
                </div>
              </div>


              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/kb.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>KB</h5>
                    <h4>KB Active Carbon</h4>
                    <span>Company who create product from raw materials and also it is ERP software.</span>
                    <h6>April 2023</h6>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/rishi.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Rishi Corporation</h5>
                    <h4>Rishi Corporation</h4>
                    <span>Rishi corporation sell direct products and that counting they manage thought this website which workes only from company side and also it is ERP software.</span>
                    <h6>July 2023</h6>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/neeyum.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Neeyum Solutions LLP</h5>
                    <h4>Neeyum Solutions LLP</h4>
                    <span>It is software development company that has created an innovative ERP product designed specifically for the healthcare industry. The company's flagship product is a comprehensive diagnostic solution that integrates pathology and laboratory functions into a single system.</span>
                    <h6>August 2020</h6>
                  </div>
                </div>
              </div>

              
              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/jamattu.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Jamattu</h5>
                    <h4>Jamattu</h4>
                    <span>Application provides ratings for customers so that shopkeeps can recognise customer status by rating and treat them accroding.</span>
                    <h6>August 2020</h6>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/valocean logo.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Valocean</h5>
                    <h4>Valocean</h4>
                    <span>Website offers you to connect with friends and also take reviews and rating for applications and also for marketing purpose.</span>
                    <h6>August 2021</h6>
                  </div>
                </div>
              </div>


              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/axis.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Axis Design</h5>
                    <h4>Axis design</h4>
                    <span>Architech and engineers company provide fine designs for your Home.</span>
                    <h6>December 2022</h6>
                  </div>
                </div>
              </div>


              <div
                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300">
                <div class="project">
                  <div class="project-img">
                    <img src="assets/img/team/satshastra.png" class="img-fluid" alt="" />
                  </div>
                  <div class="project-info">
                    <h5>Satshastra</h5>
                    <h4>Satshastra</h4>
                    <span>Application offers you All the Shastra of Swaminarayan. </span>
                    <h6>January 2022</h6>
                  </div>
                </div>
              </div>

              


              
              

            </div>
          </div>
        </section>
        <Footer />
      </div>
      );
}
