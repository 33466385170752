import "./App.css";
import Navbars from "./components/navbar";
import Footer from "./components/footer";
import Contact from "./components/contact";
import Hero from "./components/hero";
import MainSection from "./components/main";
import Team from "./components/team";
import Client from "./components/client";
import React from "react";

import { db } from "./config/firebase";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import Service from "./components/services";
import "bootstrap/dist/css/bootstrap.min.css";
import TechSlider from "./components/techSlider";
import ModalGif from "./components/modal";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import InstagramIcon from "@material-ui/icons/Instagram";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import EditIcon from "@material-ui/icons/Edit";
import Topbar from "./components/topbar";
import Portfolio from "./components/portfolio";

function WebsiteApp() {
  const [OpenModal, setOpenModal] = useState(true);
  const [LoaderContent, setLoaderContent] = useState([]);

  // useEffect(() => {
  //   db.collection("LoadingPage")
  //     .get()
  //     .then((querySnapshot) => {
  //       setOpenModal(
  //         querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //       );
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  //   return () => { };
  // }, []);

  // useEffect(() => {
  //   db.collection("LoadingPage")
  //     .get()
  //     .then((querySnapshot) => {
  //       setLoaderContent(
  //         querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //       );
  //       // querySnapshot.forEach((doc) => {
  //       //   setCheckGif(doc.data().check_gif);
  //       //   console.log(doc.id, " => ", doc.data());
  //       // });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  //   return () => { };
  // }, []);

  const style = {
    margin: 0,
    right: 20,
    bottom: 20,
    position: "fixed",
  };

  const actions = [
    {
      icon: <i class="bi bi-skype"></i>,
      name: "Skype",
      link: "https://join.skype.com/invite/XgK2S5ptfxxd",
    },
    {
      icon: <i class="bi bi-facebook"></i>,
      name: "Facebook",
      link: "https://www.facebook.com/OdhavTechnologies/",
    },
    {
      icon: <i class="bi bi-linkedin"></i>,
      name: "LinkedIn",
      link: "https://www.linkedin.com/authwall?trk=gftrkInfo=AQGoEUmj62pA9QAAAXxFE36wPUO73EsfWXKyR4lEY5Ug-M1EgmUZfTpj4ufFpS3OSAiQ8FRba4i0Gh17n-TrmjiISr06gYsTlZckG3_kB1xFNQK3e24mAtCt7Ce35vb3c8c-SHU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fodhav-technologies-539885219",
    },
    {
      icon: <i class="bi bi-instagram"></i>,
      name: "Instagram",
      link: "https://www.instagram.com/odhavtechnologies/",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <div className="App">
        <ToastContainer />
        <Navbars />
        <Topbar />
        <Hero />
        <MainSection />
        <Client />
        {/* <Portfolio /> */}
        <Service />
        <TechSlider />
        {/* <Team /> */}
        <Contact />
        <Footer />
        {/* {LoaderContent.map((value) => {
          console.log(value.data.check_gif);
          if (value.data.check_gif === true) {
            console.log(value.data.check_gif);

            return (
              <ModalGif
                OpenModal={OpenModal}
                onHide={() => setOpenModal(false)}
                LoaderContent={LoaderContent}
              />
            );
          } else {
            return "";
          }
        })} */}
      </div>
    </div>
  );
}

export default WebsiteApp;
