import React, { useEffect, useState } from "react";
import "../App.css";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { BlogsApi } from "../config/API";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function Blogs() {
  const history = useHistory();
  const [data, setdata] = useState("");

  useEffect(() => {
    getdata();
    Aos.init({ duration: 1000 })
  }, []);

  const getdata = () => {
    BlogsApi.RecentBlog()
      .then((response) => {
        setdata(response.data)
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!data) return null;

  return (
    <main id="main">

      <div class="breadcrumbs">
        <div class="page-header d-flex align-items-center">
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 text-center">
                <h2>Our Blog Posts</h2>
                <p>Our core competencies lies in varied areas of information technology. Our team is build for providing professional support to clients based on their needs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">

          <div class="row g-5">

            <div class="col-lg-12" data-aos="fade-up" data-aos-delay="200">

              <div class="row gy-5 posts-list">

                {data.data.map((blog) => (
                  <>
                    <div class="col-lg-4">
                      <article class="d-flex flex-column" onClick={() => { history.push({ pathname: `/blogpage`, state: { id: blog.id } }); }}>

                        <div class="post-img">
                          <img src={blog.main_image} alt="" class="img-fluid" />
                        </div>

                        <div class="post-data">

                          <div class="meta-top">
                            <ul>
                              <li class="d-flex align-items-center"><i class="bi bi-person"></i>{blog.author}</li>
                              <li class="d-flex align-items-center"><i class="bi bi-clock"></i>{moment(blog.created_at).format('MMMM D, YYYY')}</li>
                            </ul>
                          </div>

                          <p>
                            <h2 class="title">{blog.title}</h2>
                          </p>

                        </div>

                      </article>
                    </div>
                  </>

                ))}

              </div>

            </div>

          </div>

        </div>
      </section>
    </main>
  );
}