import "./App.css";
import Navbars from "./components/navbar";
import Footer from "./components/footer";
import BlogPages from "./components/blogpage";
import Topbar from "./components/topbar";

export default function BlogDetails() {
    return (
        <div>
            <div className="App">
                <Navbars />
                <Topbar />
                <BlogPages />
                <Footer />
            </div>
        </div>
    );
}