import axios from "axios";
class Api {
  static _axios = null;

  static instance() {
    if (this._axios == null) {
      this._axios = axios.create({
        baseURL: "https://otbk.odhavtech.com/api/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
          secure: false
        },
      });
    }
    return this._axios;
  }

  static toJSON(data) {
    var object = {};
    data.forEach((value, key) => (object[key] = value));
    return object;
  }
}

export class BlogsApi {
  static RecentBlog() {
    return Api.instance().get("/blogs");
  }
  static FindBlogbyId(id) {
    return Api.instance().get("/blogs/" + id);
  }
}

export class TeamsApi {
  static Teams() {
    return Api.instance().get("/teams");
  }
}
