import React, { useState } from "react";
import "../App.css";
import { ButtonGroup } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
function Footer() {
  const [Email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // toast.loading("Loading....");
    // db.collection("Subscription")
    //   .add({
    //     email: Email,
    //   })
    //   .then((response) => {
    //     toast.dismiss();
    //     toast.success("Subscription Added", {
    //       position: "top-center",
    //       autoClose: false,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: 0,
    //     });
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     toast.error(err);

    //     console.log(err);
    //   });
  };
  return (
    <div>

      <footer id="footer">

        {/* <div class="footer-newsletter">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email Address For Subscription....."
                    class="form-control"
                    required
                    value={Email}
                    onChange={(v) => setEmail(v.target.value)}
                  />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <div class="footer-top">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-3 col-md-6 footer-contact cardContactI">
                <h4><b>India</b></h4>
                <p>
                  217, Time Square Empire, <br />
                  Mirzapar Bypass Rd, Bhuj, <br />
                  -370001 <br />
                  <br />
                  <strong>Phone:</strong><a href="tel:+919586592853"> +91 9586592853</a> 
                  <br />
                  <strong>Business WhatsApp:</strong><a href="https://wa.me/919586592853"> +91 9586592853</a>
                  <br />
                  <strong>Email:</strong> <a href="mailto:contact@odhavtechnologies.com">contact@odhavtechnologies.com</a>
                  <br />
                </p>
              </div>
              <br />
              <div class="col-lg-3 col-md-6 footer-contact cardContactC" >
                <h4>Canada</h4>

                <p>
                  Vernon,British Columbia, <br />
                  Canada <br />
                  <br />
                  <strong>Phone:</strong><a href="tel:+12507774099">+12507774099</a> , <a href="tel:+12366009007">+12366009007</a>
                  <br />
                  <strong>Business WhatsApp:</strong><a href="https://wa.me/919265753149"> +91 9265753149</a>
                  <br />
                  <strong>Email:</strong> <a href="mailto:contact@odhavtechnologies.com">contact@odhavtechnologies.com</a>
                  <br />
                </p>
              </div>
              <br />
              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">Web Design & Development</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">Mobile App Development</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">ERP Solutions</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">E-Commerce Solutions</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">Technical Support</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>
                    <a href="/#services">Domain & Hosting</a>
                  </li>
                </ul>
              </div>
              <br />
              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>
                  Odhav Technologies (OT) is a software development company
                  based in Bhuj town of Kutch district in Gujarat.
                </p>
                <div class="social-links mt-3">
                  <a
                    href="https://join.skype.com/invite/XgK2S5ptfxxd"
                    class="skype"
                    target="_blank"
                  >
                    <i class="bi bi-skype"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/OdhavTechnologies/"
                    class="facebook"
                    target="_blank"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/odhavtechnologies/"
                    class="instagram"
                    target="_blank"
                  >
                    <i class="bi bi-instagram bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGoEUmj62pA9QAAAXxFE36wPUO73EsfWXKyR4lEY5Ug-M1EgmUZfTpj4ufFpS3OSAiQ8FRba4i0Gh17n-TrmjiISr06gYsTlZckG3_kB1xFNQK3e24mAtCt7Ce35vb3c8c-SHU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fodhav-technologies-539885219"
                    class="linkedin"
                    target="_blank"
                  >
                    <i class="bi bi-linkedin bx bxl-linkedin"></i>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="copyright">Copyright ©2022.<strong><span> OdhavTechnologies</span></strong>. All Rights Reserved</div>
      </footer>
    </div>
  );
}

export default Footer;
