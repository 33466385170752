import { useEffect, useState } from "react";
import "../App.css";
import Carousel from "react-bootstrap/Carousel";
import image1 from "../img/1.jpg";
import image2 from "../img/slid2.jpg";
import Aos from 'aos';
import 'aos/dist/aos.css';

import GetImgFromStorage from "../widget/GetImgFromStorage";
import { ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import Button from "@restart/ui/esm/Button";

function Hero() {
  const [HeadingData, setHeadingData] = useState([]);
  const [Slider, setSlider] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })

  // useEffect(() => {
  //   db.collection("HeroHeading")
  //     .get()
  //     .then((querySnapshot) => {
  //       setHeadingData(
  //         querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //       );
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  //   return () => { };
  // }, []);
  // useEffect(() => {
  //   db.collection("Slider")
  //     .get()
  //     .then((querySnapshot) => {
  //       setSlider(
  //         querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //       );
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  //   return () => { };
  // }, []);
  return (
    // <section id="hero" style={{ padding: 0, marginTop: "100px" }} data-aos="zoom-in-up" >
    //   <Carousel>
    //     <Carousel.Item>
    //       <img className="d-block w-100" src={image1} alt="First slide" />
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img className="d-block w-100" src={image2} alt="Second slide" />
    //     </Carousel.Item>
    //   </Carousel>
    // </section>

    <section id="hero">

      <div class="carousel-container">
        <div class="container">
          <h2>Welcome to <span>Odhav Technologies</span></h2>
          <p class="hero-subtitle"><span class="typed" data-typed-items="Designer, Developer, Freelancer, Photographer"></span></p>

          <p>A Strategic approach to IT solutions and development of your business.</p>
          <a href="#about" class="btn-get-started">About Us</a>
        </div>
      </div>

    </section>
  );
}

export default Hero;
