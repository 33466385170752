import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router";
import OwlCarousel from "react-owl-carousel";

function Team() {
  const [Team, setTeam] = useState([]);

  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const history = useHistory();

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    dots: true,
    items: 2,
    loop: true,
    smartSpeed: 2000,
    checkVisible: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <div>
      <section id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Team</h2>
          </div>

          <div className="webTeam">
            <div class="row gy-4">
              {/* <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-1.png"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="social">
                      <a
                        href="https://twitter.com/PatelSaurbah?s=09"
                        target="_blank"
                      >
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a
                        href="https://instagram.com/saurabhpatel8767?utm_medium=copy_link"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100064926111571"
                        target="_blank"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/saurabh-ramani-331208217"
                        target="_blank"
                      >
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div class="member-info">
                    <h4>Saurabh Ramani</h4>
                    <span>Chief Executive Officer</span>
                  </div>
                </div>
              </div> */}

              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-2.png"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="social">
                      <a
                        href="https://twitter.com/Uday__ramani"
                        target="_blank"
                      >
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/UdayRamani25"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/uday__ramani/"
                        target="_blank"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://in.linkedin.com/in/uday-ramani-ab161615b?trk=people-guest_people_search-card"
                        target="_blank"
                      >
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div class="member-info">
                    <h4>Uday Ramani</h4>
                    <span>Chief Technical Officer</span>
                  </div>
                </div>
              </div>

              {/* <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-3.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="member-info">
                    <h4>Kaushal Dholu</h4>
                    <span>Mobile Application Developer</span>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-4.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="member-info">
                    <h4>Pritesh Kerai</h4>
                    <span>Web Developer</span>
                  </div>
                </div>
              </div> */}
              <div class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
                className="more-info"
                onClick={() => history.push({ pathname: `/teams` })}
              >
                More Info&nbsp;<i class="bi bi-arrow-right"></i>
              </div>
            </div>


            {/* <div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
              <div class="member">
                <div class="member-img">
                  <img src="assets/img/team/team-5.png" class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Bhakti Varsani</h4>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="300">
              <div class="member">
                <div class="member-img">
                  <img src="assets/img/team/team-6.png" class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Khushbu Gohil</h4>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>

            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="400">
              <div class="member">
                <div class="member-img">
                  <img src="assets/img/team/team-7.png" class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Hiral Patel</h4>
                  <span>Web Developer</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="mobileTeam">
            <OwlCarousel className="slider-items owl-carousel" {...options}>
              {/* <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-1.png"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="social">
                      <a
                        href="https://twitter.com/PatelSaurbah?s=09"
                        target="_blank"
                      >
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a
                        href="https://instagram.com/saurabhpatel8767?utm_medium=copy_link"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100064926111571"
                        target="_blank"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/saurabh-ramani-331208217"
                        target="_blank"
                      >
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div class="member-info">
                    <h4>Saurabh Ramani</h4>
                    <span>Chief Executive Officer</span>
                  </div>
                </div>
              </div> */}

              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-2.png"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="social">
                      <a
                        href="https://twitter.com/Uday__ramani"
                        target="_blank"
                      >
                        <i class="bi bi-twitter"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/UdayRamani25"
                        target="_blank"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/uday__ramani/"
                        target="_blank"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://in.linkedin.com/in/uday-ramani-ab161615b?trk=people-guest_people_search-card"
                        target="_blank"
                      >
                        <i class="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div class="member-info">
                    <h4>Uday Ramani</h4>
                    <span>Chief Technical Officer</span>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-3.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="member-info">
                    <h4>Kaushal Dholu</h4>
                    <span>Mobile Application Developer</span>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div class="member">
                  <div class="member-img">
                    <img
                      src="assets/img/team/team-4.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="member-info">
                    <h4>Pritesh Kerai</h4>
                    <span>Web Developer</span>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          <div
            className="more-info"
            onClick={() => history.push({ pathname: `/teams` })}
          >
            More Info&nbsp;<i class="bi bi-arrow-right"></i>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
