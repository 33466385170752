import React, { useEffect, useState } from "react";
import "../App.css";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
function MainSection() {
  useEffect(() => {
    Aos.init({ duration: 1500 })
  })

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    dots: true,
    items: 1,
    loop: true,
    smartSpeed: 1000,
    checkVisible: true,
    responsive: {
      0: {
        items: 1,
      },
      // 400: {
      //   items: 2,
      // },
      // 600: {
      //   items: 2,
      // },
      // 700: {
      //   items: 3,
      // },
      // 1000: {
      //   items: 5,
      // },
    },
  };
 

  return (
    <main id="main">

      <section id="about" class="about">
        <div class="container">
          <div class="row gx-0">

            <div class="col-lg-6 d-flex flex-columns justify-content-center"
              data-aos="flip-left">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/gAR7YZuuKdM?si=tx5td2UWnVh0lGmO"
                title="Odhav Technologies"
                frameborder="12"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div class="col-lg-6 d-flex flex-columns justify-content-center"
              data-aos="zoom-in-left">
              <div class="content">
                <h3>About Our Company</h3>
                <h2>
                  Odhav Technologies is the leading software and information
                  technology solutions company that provides multi-technology
                  services with multi-skilled.
                </h2>
                <p>
                  Odhav Technologies is your one stop shop for all tech needs.
                  We combine latest technologies, tools and frameworks with our
                  experience to deliver customized,reliable and efficient
                  solutions. Odhav Technologies helps clients to reduce project
                  development cost with significant improvement in quality.
                </p>
                {/* <Link to="/projectportfolio"><h3>About Our Portfolio<i class="bi bi-arrow-right"></i></h3></Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container my-3">
        <div class="row gx-0">
      
        <div class="col-lg-4 d-flex flex-columns justify-content-center"
              data-aos="flip-left">
                  <OwlCarousel className="slider-items owl-carousel" {...options}>
              <div class="item">
               <iframe
                width="255"
                height="520"
                src="assets/img/OT.png"
                title="VyaparBook"
                frameborder="12"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              </div>
             <div class="item">
               <iframe
                width="255"
                height="520"
                src="assets/img/OT.png"
                title="VyaparBook"
                frameborder="12"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              </div>
</OwlCarousel>

              
            </div>
            <div class="col-lg-8 d-flex flex-columns justify-content-center wrapper"
              data-aos="zoom-in-left">
              <div class="content">
              <img
                width="650"
                height="450"
                src="assets/img/OT_image.png"
                frameborder="12"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></img>              
              </div>
            </div>

        </div>
        </div>
      </section> */}

    </main>
  );
}

export default MainSection;
