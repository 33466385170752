import "./App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import WebsiteApp from "./websiteApp";
import BlogApp from "./BlogApp";
import BlogDetails from "./BlogDetails";
import AllTeam from "./components/allteam";
import ProjectPortfolio from "./components/projectportfolio";
function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/teams">
            <AllTeam />
          </Route>
          <Route path="/projectPortfolio">
            <ProjectPortfolio />
          </Route>
          <Route path="/blogs">
            <BlogApp />
          </Route>
          <Route path="/blogpage">
            <BlogDetails />
          </Route>
          <Route exact path="/">
            <WebsiteApp />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
