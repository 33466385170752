import React, { useEffect, useState } from "react";
import "../App.css";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { BlogsApi } from "../config/API";
import moment from "moment";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

export default function BlogPages() {
  const history = useHistory();
  const [data, setdata] = useState("")

  useEffect(() => {
    blogdata();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 })
  })

  const blogdata = () => {
    console.log(history)
    BlogsApi.FindBlogbyId(history.location.state.id)
      .then((response) => {
        setdata(response.data);
      })
      .catch((error) => { });
  };

  if (!data) return null;

  return (
    <main id="main">

      <div class="breadcrumbs" style={{ backgroundImage: "url(" + data.data.main_image + ")" }}>
        <div class="page-header d-flex align-items-center">
          <div class="container position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 text-center">
                <h3>{data.data.title}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog" class="blogpage">
        <div class="container">

          <div class="row g-5">

            <div class="col-lg-12">

              <article class="blog-details">

                <h2 class="title" data-aos="fade-down">{data.data.title}</h2>

                <div class="meta-top">
                  <ul>
                    <li class="d-flex align-items-center"><i class="bi bi-person"></i>{data.data.author}</li>
                    <li class="d-flex align-items-center"><i class="bi bi-clock"></i>{moment(data.data.created_at).format('D MMMM, YYYY')}</li>
                  </ul>
                </div>

                <div class="content" data-aos="fade-right">
                  {parse(data.data.content)}
                </div>

              </article>

            </div>
          </div>

        </div>
      </section>

    </main>
  );
}